<template>
  <div class="landing-inner-banner">
    <h1 class="text-center mt-3 mb-0 mx-1" style="font-weight: bold;">{{$t("hotel-page.hotel-breadcrumb-dest-third-clone",
      {destinationName: destinationName})}}</h1>
    <div class="container">
      <div class="row">
        <LandingBannerSlide :dealTypeCode="dealTypeCode"/>
        <LandingContact :dealTypeCode="dealTypeCode"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LandingBanner',
  components: {
    LandingBannerSlide: () => import('@/components/landing/LandingBannerSlide'),
    LandingContact: () => import('@/components/landing/LandingContact'),
  },
  props: {
    dealTypeCode: String,
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_CAMINGO_DESTINATION',
    }),
    query() {
      const route = this.$route;
      return route.query;
    },
    cityId() {
      return this.query.destination;
    },
    hotelCode() {
      return this.query.hotelCode;
    },
    destinationName() {
      if (this.destinationList.length === 0) return '';
      return this.destinationList.find((elem) => elem.code === this.cityId).name;
    },
  },
};
</script>

<style scoped>
  .landing-inner-banner {
    width: 100%;
    background: url('/assets/img/banner_landing.jpg');
    background-size: 100% 100%;
    padding-top: 30px;
  }
  .container{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  @media (min-width: 1200px){
    .container {
        max-width: 1140px;
    }
  }
</style>
